/* ItemMixins
 * Mixins for Items list routes
*/
import api from '../api'
import debounce from 'lodash.debounce'
import { mdiPlus } from '@mdi/js'
import EventBus from '@/components/EventBus'

export default {
  data () {
    return {
      mdiPlus: mdiPlus,
      loading: true,
      user: this.$store.state.user,
      // availablePermissions: this.$store.state.availablePermissions,
      items: [],
      selected: [], // holds tables selected items
      selectedIndex: false, // selected index without actual displayed items
      selectedItemId: 0, // selected natural id
      editMode: false, // client module editMode => do not allow change of selected
      actualClientId: false,
      totalItems: 0,
      options: this.defaultOptions(),
      footerProps: {
        'items-per-page-options': [10, 25, 100, -1]
      }

    }
  },
  computed: {
    userCanEdit () {
      return this.$store.state.user.rights & this.$store.state.availablePermissions[this.editRight]
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: 'parseQuery'
    },
    headers: {
      deep: true,
      handler: 'setFilters'
    },
    'options.itemsPerPage': function (itemsPerPage) {
      if (this.$store.state.hasLocalStorage) {
        // save as cookie
        window.localStorage.setItem(this.itemsName + 'ItemsPerPage', itemsPerPage)
        // reload manually, since we no longer update itemsPerPage in router query
        this.getItems()
      }
    }
  },
  mounted () {
    // ctrl+ins => createItem
    EventBus.$on('ctrlKey_Ins', (e) => {
      console.log('ctrlKey_Ins')
      if (!this.editMode && this.selectedIndex === false) {
        e.preventDefault()
        this.createItem()
      }
    })
    // enter on selected item => goto item
    EventBus.$on('keyEnter', (e) => {
      if (!this.editMode && this.selectedIndex !== false) {
        this.showItem(this.items[this.selectedIndex])
      }
    })
    // esc => unselect item
    EventBus.$on('keyEsc', (e) => {
      if (!this.editMode) {
        this.selectedIndex = false
        this.selected = []
      }
    })
    EventBus.$on('keyDown', (e) => {
      if (!this.editMode) {
        // goto next page
        if (this.selectedIndex >= this.items.length - 1) {
          if (this.options.page * this.options.itemsPerPage < this.totalItems) {
            this.options.page += 1
            this.updateQuery()
            this.selectedIndex = 0
          }
        } else {
          this.selectedIndex = (this.selectedIndex !== false) ? this.selectedIndex + 1 : 0
          this.selected = [this.items[this.selectedIndex]]
          // show directly if inlineItem
          if (this.inlineItem) {
            this.showItem(this.items[this.selectedIndex])
          }
        }
      }
    })
    EventBus.$on('keyUp', (e) => {
      if (!this.editMode) {
        // goto previous page
        if (this.selectedIndex === 0) {
          if (this.options.page !== 1) {
            this.options.page -= 1
            this.updateQuery()
            this.selectedIndex = this.items.length - 1
          }
        } else {
          this.selectedIndex = (this.selectedIndex > 0) ? this.selectedIndex - 1 : this.items.length - 1
          this.selected = [this.items[this.selectedIndex]]
          // show directly if inlineItem
          if (this.inlineItem) {
            this.showItem(this.items[this.selectedIndex])
          }
        }
      }
    })
    // goto next page
    EventBus.$on('keyNextPage', (e) => {
      if (!this.editMode) {
        if (this.options.page * this.options.itemsPerPage < this.totalItems) {
          this.options.page += 1
          this.updateQuery()
          // show directly if inlineItem
          if (this.inlineItem) {
            this.selectedIndex = 0
            this.showItem(this.items[this.selectedIndex])
          } else {
            this.selectedIndex = false
          }
        }
      }
    })
    // goto previous page
    EventBus.$on('keyPrevPage', (e) => {
      if (!this.editMode) {
        if (this.options.page !== 1) {
          this.options.page -= 1
          this.updateQuery()
          // show directly if inlineItem
          if (this.inlineItem) {
            this.selectedIndex = this.items.length - 1
            this.showItem(this.items[this.selectedIndex])
          } else {
            this.selectedIndex = false
          }
        }
      }
    })
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_Ins')
    EventBus.$off('keyEnter')
    EventBus.$off('keyEsc')
    EventBus.$off('keyDown')
    EventBus.$off('keyUp')
    EventBus.$off('keyNextPage')
    EventBus.$off('keyPrevPage')
  },
  methods: {
    savedItemsPerPage (defaultValue) {
      if (!this.$store.state.hasLocalStorage) {
        return defaultValue
      }
      const itemsPerPage = window.localStorage.getItem(this.itemsName + 'ItemsPerPage')
      return (itemsPerPage === null) ? defaultValue : +itemsPerPage
    },
    defaultOptions () {
      return {
        sortBy: [this.itemName + 'Id'], sortDesc: [true], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    },
    createItem () {
      if (this.inlineItem) {
        this.$refs[this.itemName].createItem()
      } else {
        // shown as sub elemento of client => push to special route
        if (this.fixedClientId) {
          this.$router.push({
            name: 'clientsub',
            params: {
              id: '' + this.fixedClientId,
              subId: 'new_'
            }
          })
        } else {
          this.$router.push({
            name: this.itemName,
            params: {
              id: 'new_'
            }
          })
        }
      }
    },
    showItem (item) {
      if (this.inlineItem) {
        this.selectedItemId = item[this.itemName + 'Id']
      } else {
        // shown as sub elemento of client => push to special route
        if (this.fixedClientId) {
          this.$router.push({
            name: 'clientsub',
            params: {
              id: '' + this.fixedClientId,
              subId: '' + item[this.itemName + 'Id']
            }
          })
        } else {
          this.$router.push({
            name: this.itemName,
            params: {
              id: '' + item[this.itemName + 'Id']
            }
          })
        }
      }
    },
    // create a select list with prepend 'All' option
    getSelectList (selectListName, keyName) {
      keyName = keyName || 'key' // set default to key
      const values = [{ text: 'All', value: '' }]
      const list = this.$store.state[selectListName] || []
      for (const el of list) {
        values.push(el[keyName])
      }
      return values
    },
    setFilters: debounce(function () {
      this.updateQuery()
    }, 300),
    getItems () {
      console.log('getItems')
      if (!this.itemsName) {
        console.error('itemsName not set')
        return
      }

      this.loading = true
      let filters = this.getFilters()
      if (this.fixedClientId) {
        filters = this.replaceFilterClientId(filters, this.fixedClientId)
      }
      api.getItems(this.itemsName, { ...this.options, ...filters })
        .then(data => {
          this.loading = false
          if (!data.items) {
            return
          }
          this.items = data.items
          this.totalItems = data.totalItems
          // move cursor to first row
          if (this.selected) {
            if (this.selectedIndex === 0) {
              this.selected = [this.items[0]]
            } else if (this.selectedIndex === data.items.length - 1) {
              this.selected = [this.items[data.items.length - 1]]
            }
          }
        })
        .catch(e => {
          this.loading = false
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    replaceFilterClientId (filters, clientId) {
      const replacedFilters = {
        filterCols: [],
        filterSearches: [],
        filterValues: []
      }
      if (filters.filterValues && filters.filterValues.length > 0) {
        // deep copy
        replacedFilters.filterCols = [...filters.filterCols]
        replacedFilters.filterSearches = [...filters.filterSearches]
        replacedFilters.filterValues = [...filters.filterValues]
      }

      // remove existing respPers filter
      const pos = replacedFilters.filterCols.indexOf('clientId')
      if (pos !== -1) {
        replacedFilters.filterCols.splice(pos, 1)
        replacedFilters.filterSearches.splice(pos, 1)
        replacedFilters.filterValues.splice(pos, 1)
      }
      replacedFilters.filterCols.push('clientId')
      replacedFilters.filterSearches.push('full')
      replacedFilters.filterValues.push(clientId)
      // console.log(filters)

      return replacedFilters
    },
    // convert filters in headers to qry compatible object
    getFilters () {
      const filterCols = []
      const filterSearches = []
      const filterValues = []
      for (const el of this.headers) {
        // if (typeof el.filterValue !== 'undefined' && (typeof el.filterValue !== 'string' || el.filterValue.trim() !== '')) {
        if (el.filterValue && el.filterValue.trim() !== '') {
          filterValues.push(el.filterValue.trim())
          filterCols.push(el.value)
          filterSearches.push(el.filterSearch)
        }
      }
      const filters = {}
      if (filterValues.length > 0) {
        filters.filterCols = filterCols
        filters.filterSearches = filterSearches
        filters.filterValues = filterValues
      }
      // console.log('filters from mixins', filters)
      return filters
    },
    updateQuery () {
      const qry = Object.assign({}, this.$route.query)
      const defaultQry = this.defaultOptions()
      let isDirty = false

      // exclude non default options
      console.log('updateQuery page ', this.options.page)
      if (this.options.page === defaultQry.page) {
        if (qry.page) {
          delete qry.page
          isDirty = true
        }
      } else {
        if (!qry.page || +qry.page !== this.options.page) {
          qry.page = '' + this.options.page
          isDirty = true
        }
      }
      /* do not show itemsPerPage in qry
      if (this.options.itemsPerPage === defaultQry.itemsPerPage) {
        if (qry.itemsPerPage) {
          delete qry.itemsPerPage
          isDirty = true
        }
      } else {
        if (!qry.itemsPerPage || +qry.itemsPerPage !== this.options.itemsPerPage) {
          qry.itemsPerPage = '' + this.options.itemsPerPage
          isDirty = true
        }
      }
      */

      // we get sortDesc as string => cast to true, false, undefined
      qry.sortDesc = (typeof qry.sortDesc === 'undefined') ? undefined : (qry.sortDesc === 'true')
      // sortBy empty or default (or undefined, which is the same as default)
      if (!this.options.sortBy || this.options.sortBy[0] === defaultQry.sortBy[0] || typeof this.options.sortBy[0] === 'undefined') {
        // remove from qry if necessary
        if (typeof qry.sortBy !== 'undefined') {
          qry.sortBy = undefined
          isDirty = true
        }
        if (typeof qry.sortDesc === 'undefined') {
          if (typeof this.options.sortDesc[0] !== 'undefined' && this.options.sortDesc[0] !== defaultQry.sortDesc[0]) {
            qry.sortDesc = this.options.sortDesc[0]
            isDirty = true
          }
        } else {
          if (this.options.sortDesc[0] === defaultQry.sortDesc[0]) {
            qry.sortDesc = undefined
            isDirty = true
          }
        }
      // sortBy not default
      } else {
        if (typeof qry.sortBy === 'undefined' || qry.sortBy !== this.options.sortBy[0]) {
          qry.sortBy = this.options.sortBy[0]
          isDirty = true
        }
        // sortDesc different or not set on qry
        console.log(qry.sortDesc)
        if (typeof qry.sortDesc === 'undefined' || qry.sortDesc !== this.options.sortDesc[0]) {
          qry.sortDesc = this.options.sortDesc[0]
          isDirty = true
        }
      }

      // update filters in query
      for (const el of this.headers) {
        if (el.filterValue && el.filterValue.trim() !== '' && qry[el.value] !== el.filterValue.trim()) {
          qry[el.value] = el.filterValue.trim()
          // return to page 1 after filter change
          delete qry.page
          isDirty = true
        } else if ((!el.filterValue || el.filterValue.trim() === '') && qry[el.value]) {
          delete qry[el.value]
          // return to page 1 after filter change
          delete qry.page
          isDirty = true
        }
      }
      if (isDirty) {
        this.$router.push({ query: qry })
      }
    },
    parseQuery () {
      // check if query has changes
      const qry = Object.assign({}, this.$route.query)
      const defaultQry = this.defaultOptions()

      // make sure defaults are used
      if (!qry.page) {
        this.options.page = defaultQry.page
      } else if (+qry.page !== this.options.page) {
        this.options.page = +qry.page
      }

      if (!qry.itemsPerPage) {
        this.options.itemsPerPage = defaultQry.itemsPerPage
      } else if (+qry.itemsPerPage !== this.options.itemsPerPage) {
        this.options.itemsPerPage = +qry.itemsPerPage
      }

      // we get sortDesc as string => cast to true, false, undefined
      qry.sortDesc = (typeof qry.sortDesc === 'undefined') ? undefined : (qry.sortDesc === 'true')

      if (typeof qry.sortBy === 'undefined') {
        this.options.sortBy = defaultQry.sortBy
        if (typeof qry.sortDesc === 'undefined') {
          this.options.sortDesc = defaultQry.sortDesc
        } else {
          this.options.sortDesc = [qry.sortDesc]
        }
      } else {
        this.options.sortBy = [qry.sortBy]
        if (typeof qry.sortDesc === 'undefined') {
          // applies default sortBy / sortDesc
          this.options.sortBy = defaultQry.sortBy
          this.options.sortDesc = defaultQry.sortDesc
        } else {
          this.options.sortDesc = [qry.sortDesc]
        }
      }
      // console.log(JSON.stringify(this.options))

      for (const el of this.headers) {
        if (typeof qry[el.value] !== 'undefined' && el.filterValue !== qry[el.value]) {
          el.filterValue = qry[el.value]
        }
      }
      this.getItems()
    }
  }
}
