/* ItemMixins
 * Mixins for Item routes (CRUD)
*/
import api from '../api'
import debounce from 'lodash.debounce'
import EventBus from '@/components/EventBus'
import Vue from 'vue'

export default {
  data () {
    return {
      valid: false,
      editMode: false,
      isSaving: false,
      item: {},
      user: this.$store.state.user
      // availablePermissions: this.$store.state.availablePermissions,
    }
  },
  computed: {
    userCanEdit () {
      return this.$store.state.user.rights & this.$store.state.availablePermissions[this.editRight]
    },
    routeItemId () {
      if (this.fixedClientId) {
        return this.$route.params.subId
      } else {
        console.log('no fixedClientId: ' + this.fixedClientId)
        return this.$route.params.id
      }
    },
    modelItemId: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    // id may be set through module model or router
    routeItemId: {
      immediate: true,
      handler: function (id) {
        !this.inlineItem && this.getItem(id)
      }
    },
    modelItemId: {
      immediate: true,
      handler: function (id) {
        console.log('modelItemId', this.inlineItem, id)
        this.inlineItem && this.getItem(id)
      }
    },
    // focus first input when entering editMode
    editMode: {
      handler: function (editMode) {
        if (editMode === true) {
          this.$nextTick(function () {
            if (this.$refs.focussedInput) {
              this.$refs.focussedInput.focus()
            } else {
              console.log('missing focussedInput')
            }
          })
        }
        // parent has to know editMode state
        this.$emit('editMode', editMode)
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }
  },
  mounted () {
    // document.addEventListener('keydown', this.keyShortcut)
    // EventBus.$off('handledKey')
    EventBus.$on('ctrlKey_s', (e) => {
      console.log('ctrlKey_s')
      e.preventDefault()
      this.save()
    })
    // esc => unselect item
    EventBus.$on('keyEsc', (e) => {
      if (this.editMode) {
        this.cancel()
      } else {
        this.close()
      }
    })
    EventBus.$on('keyF2', (e) => {
      e.preventDefault()
      this.editMode = true
    })
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_s')
    EventBus.$off('keyEsc')
    EventBus.$off('keyF2')

    // document.removeEventListener('keydown', this.keyShortcut)
  },
  methods: {
    userCan (right) {
      return this.$store.state.user.rights & this.$store.state.availablePermissions[right]
    },
    getItem (id) {
      this.editMode = false
      console.log('getItem: ' + id)

      if (!this.itemsName) {
        console.error('itemsName not set')
        return
      }
      // not selected
      if (id === 0 || typeof id === 'undefined') {
        this.item = {}
        return
      }

      // don't fetch for new
      if (id === 'new_' || this.item.isNew) {
        console.log('create again', this.item)
        this.createItem(this.item.cn)
        return
      }
      api.getItem(this.itemsName, id)
        .then(data => {
          this.item = data
          // open new and unfinished items directly in edit mode
          Vue.nextTick(() => {
            if (!this.$refs.form.validate()) {
              this.editMode = true
            }
          })
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    save: debounce(function () {
      if (!this.itemsName) {
        console.error('itemsName not set')
        return
      }
      if (!this.itemName) {
        console.error('itemName not set')
        return
      }
      if (!this.$refs.form.validate()) {
        return
      }
      this.isSaving = true
      const id = this.item[this.itemName + 'Id']
      if (id === 'new_' || this.item.isNew) {
        api.createItem(this.itemsName, this.item)
          .then(data => {
            this.editMode = false
            this.isSaving = false
            this.$store.commit('alert',
              {
                show: true,
                text: this.itemCreateText || 'Elemento creado',
                type: 'success'
              }
            )
            this.$emit('saved')
            if (this.inlineItem) {
              this.$emit('refreshList')
              this.modelItemId = data[this.itemName + 'Id']
            } else {
              // avoid blocking reload
              this.item.isNew = false
              // replace in history and trigger reload
              // shown as sub elemento of client => special route
              if (this.fixedClientId) {
                this.$router.replace({
                  name: 'clientsub',
                  params: {
                    id: '' + this.fixedClientId,
                    submenu: this.itemsName,
                    subId: '' + data[this.itemName + 'Id']
                  }
                })
              } else {
                this.$router.replace({
                  name: this.itemName,
                  params: {
                    id: '' + data[this.itemName + 'Id']
                  }
                })
              }
            }
          })
          .catch(e => {
            this.isSaving = false
            this.$store.commit('alert',
              {
                show: true,
                text: e.message,
                type: 'error'
              }
            )
          })
      } else {
        api.updateItem(this.itemsName, id, this.item)
          .then(data => {
            this.item = data
            this.editMode = false
            this.isSaving = false
            this.$store.commit('alert',
              {
                show: true,
                text: this.itemUpdateText || 'Elemento actualizado',
                type: 'success'
              }
            )
            this.$emit('saved')
            if (this.inlineItem) {
              this.$emit('refreshList')
              if (typeof this.show !== 'undefined') {
                this.show = false
              }
            }
          })
          .catch(e => {
            this.isSaving = false
            this.$store.commit('alert',
              {
                show: true,
                text: e.message,
                type: 'error'
              }
            )
          })
      }
    }, 300),
    // unselect and close action expanded or return to list
    close () {
      if (this.inlineItem) {
        this.modelItemId = 0
        if (typeof this.show !== 'undefined') {
          this.show = false
        }
      } else {
        // this.$router.push({ name: this.listRouteName })
        // just go back to list
        this.$router.go(-1)
      }
    },
    // cancel edit
    cancel () {
      const id = this.item[this.itemName + 'Id']
      // remove unsaved item or reset item
      if (id === 'new_' || this.item.isNew) {
        this.close()
      } else {
        this.editMode = false
        this.getItem(id)
      }
    },
    remove: debounce(function () {
      if (!this.itemsName) {
        console.error('itemsName not set')
        return
      }
      api.removeItem(this.itemsName, this.item[this.itemName + 'Id'])
        .then(() => {
          this.$store.commit('alert',
            {
              show: true,
              text: this.itemRemoveText || 'Elemento borrado',
              type: 'success'
            }
          )
          this.$emit('removed')
          if (this.inlineItem) {
            // this.modelItemId = ''
            this.$emit('refreshList')
          }
          this.close()
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: e.message,
              type: 'error'
            }
          )
        })
    }, 300)
  }
}
